import styled from "styled-components"
import {
  Button,
  Card,
  Checkbox,
  Collapse, 
  Divider, 
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Slide,
  TextField,
  Typography,
} from "@mui/material"
import { Add, Close } from "@mui/icons-material"
import { ToDoItem, ToDoItemFormProps, ToolTips } from "../typing/interfaces"
import { ColorSheet, DaysOfWeek } from '../typing/enums'
import { useEffect, useState } from "react"

// ----- Styles -----
const CollapseContainer = styled(Collapse)`
  position: sticky !important;
  float: right;
  top: 90%;
  padding-left: 5px;
  padding-bottom: 5px;
`

const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  pointer-events: none;
`

export const FormCard = styled(Card)`
  margin-top: -10vh;
  width: 500px;
  background-color: ${ ColorSheet.ListBackground } !important;
  pointer-events: all;
`

export const FormBanner = styled.div`
  background-color: ${ ColorSheet.AppBackground };
`

export const FormTitle = styled(Typography)`
  padding: 25px !important;
  height: 30px;
  display: inline-block;
  color: white;
`

export const FormCloseButton = styled(IconButton)`
  float: right;
  margin: 10px !important;
  color: white !important;
`

export const FormBody = styled.div`
  padding: 15px !important;
  max-height: 70vh;
  overflow: auto;
  margin-right: -50px !important;
  width: calc(100% - 30px);
`

export const ButtonBar = styled.div`
  height: 40px;
  padding: 10px;
  background-color: ${ ColorSheet.AppBackground };
`

export const FormButton = styled(Button)`
  float: right;
  margin-left: 10px !important;
`


export const ToDoItemForm = (props: ToDoItemFormProps) => {
  // ----- Init State -----
  const initId = () => props.editItem != null ? props.editItem.id : Math.max(...props.toDoList.map(item => item.id)) + 1
  const initDisplayText = () => props.editItem != null ? props.editItem.displayText : null
  const initSchedule = () => props.editItem != null ? props.editItem.schedule : null
  const initAllDays = () => initSchedule() == null
  const initToolTip = () => props.editItem != null ? props.editItem.toolTip : null
  const initDifferentToolTips = () => {
    if (props.editItem != null && props.editItem.toolTip != null) {
      const mondayToolTip = props.editItem.toolTip[DaysOfWeek.Monday]
      return !Object.values(props.editItem.toolTip).every(toolTip => toolTip === mondayToolTip)
    }
    return false
  }
  const initIsDropIn = () => props.editItem != null ? props.editItem.isDropIn : false

  // ----- Define State -----
  //   form fields
  const [formDisplayText, setFormDisplayText]: [string|null, Function] = useState(initDisplayText())
  const [formSchedule, setFormSchedule]: [Array<DaysOfWeek>|null|undefined, Function] = useState(initSchedule())
  const [formAllDays, setFormAllDays]: [boolean, Function] = useState(initAllDays())
  const [formToolTip, setFormToolTip]: [ToolTips|null|undefined, Function] = useState(initToolTip())
  const [formDifferentToolTips, setFormDifferentToolTips]: [boolean, Function] = useState(initDifferentToolTips)
  const [formIsDropIn, setFormIsDropIn]: [boolean|null, Function] = useState(initIsDropIn())
  //    form warnings
  const [warningDisplayText, setWarningDisplayText]: [string, Function] = useState('')

  // ----- Manually Recalculate Fields -----
  //    form fields
  useEffect(() => setFormDisplayText(initDisplayText()), [props.editItem])  // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => setFormSchedule(initSchedule()), [props.editItem])  // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => setFormAllDays(initAllDays()), [props.editItem])  // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => setFormToolTip(initToolTip()), [props.editItem])  // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => setFormDifferentToolTips(initDifferentToolTips()), [props.editItem])  // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => setFormIsDropIn(initIsDropIn()), [props.editItem])  // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => formAllDays ? setFormSchedule(null) : undefined, [formAllDays])  // eslint-disable-line react-hooks/exhaustive-deps
  //    form warnings
  useEffect(() => setWarningDisplayText(''), [formDisplayText])  // eslint-disable-line react-hooks/exhaustive-deps


  // ----- State Utilities -----
  const resetFields = () => {
    setFormDisplayText(null)
    setFormSchedule(null)
    setFormToolTip(null)
    setFormIsDropIn(false)
  }

  const resetFieldWarnings = () => {
    setWarningDisplayText('')
  }

  const formatSchedule = (): Array<DaysOfWeek>|undefined => {
    let formattedSchedule
    if (!formAllDays) formattedSchedule = formSchedule == null ? undefined : formSchedule

    return formattedSchedule
  }

  // ----- Event Handlers -----
  const handleFormClose = () => {
    // clear gobal values
    props.setEditItem(null)
    props.setIsEditModalOpen(false)

    // clear local variables
    resetFields()
    resetFieldWarnings()
  }

  const handleSubmit = () => {
    resetFieldWarnings()

    if (formDisplayText == null) {
      setWarningDisplayText('Cannot be blank')
      return 
    }

    const newId = initId()
    const newItem: ToDoItem = {
      id: newId > 0 ? newId : 1,
      displayText: formDisplayText.trim(),
      schedule: formatSchedule(),
      toolTip: formToolTip == null ? undefined : formToolTip,
      isChecked: props.editItem == null ? false : props.editItem.isChecked,
      isDropIn: formIsDropIn,
      dateDroppedIn: formIsDropIn ? new Date() : undefined,
    }

    let newToDoList = []
    if (props.editItem != null) newToDoList = props.toDoList.filter(item => item.id !== newItem.id)
    else newToDoList = [...props.toDoList]

    newToDoList.push(newItem)
    props.setToDoList(newToDoList)
    
    handleFormClose()
  }

  const handleDelete = () => {
    const userConfirm = window.confirm(`Are you sure you want to delete \n "${props.editItem?.displayText}"`)
    if (!userConfirm) return

    const newToDoList = props.toDoList.filter(item => item.id !== props.editItem?.id)
    props.setToDoList(newToDoList)
    handleFormClose()
  }

  const handleAllDaysSelected = () => {
    const allDaysSelected = Object.keys(DaysOfWeek).every((day) => formSchedule?.includes(day as DaysOfWeek))
    if (allDaysSelected) {
      setFormAllDays(true)
      setFormSchedule(null)
    }
  }
  useEffect(() => handleAllDaysSelected(), [formSchedule])  // eslint-disable-line react-hooks/exhaustive-deps

  const handleToolTipChanged = (value: string, dayToChange: DaysOfWeek | null = null) => {
    let newToolTip = formToolTip != null ? {...formToolTip} : {
      [DaysOfWeek.Monday]: '',
      [DaysOfWeek.Tuesday]: '',
      [DaysOfWeek.Wednesday]: '',
      [DaysOfWeek.Thursday]: '',
      [DaysOfWeek.Friday]: '',
      [DaysOfWeek.Saturday]: '',
      [DaysOfWeek.Sunday]: '',
    }

    if (dayToChange == null) {
      Object.keys(DaysOfWeek).forEach(day => {
        newToolTip[day as DaysOfWeek] = value
      })
    } else {
      newToolTip[dayToChange] = value
    }

    setFormToolTip(newToolTip)
  }

  // ----- Builders -----
  const buildToolTipFields = () => {
    if (!formDifferentToolTips) {
      return <Grid xs={12} md={12} item>
        <TextField
          label="Tooltip"
          color="info"
          fullWidth
          value={formToolTip != null ? formToolTip[DaysOfWeek.Monday] : ''}
          onChange={(e: any) => handleToolTipChanged(e.target.value)}
        />
      </Grid>
    } else {
      return Object.keys(DaysOfWeek).map(day => <Grid xs={12} md={12} item key={`${day}-tooltip`}>
        <TextField
          label={`${day} Tooltip`}
          color="info"
          fullWidth
          value={formToolTip != null ? formToolTip[day as DaysOfWeek] : ''}
          onChange={(e: any) => handleToolTipChanged(e.target.value, day as DaysOfWeek)}
        />
      </Grid>)
    }
  }

  return <>
    <Modal
      open={props.isEditModalOpen}
      onClose={handleFormClose}
      onKeyDown={(e: any) => { if (e.key === 'Enter') e.preventDefault() }}
    >
      <Slide in={props.isEditModalOpen} direction="up">
        <FormWrapper onKeyDown={(e: any) => { if (e.key === 'Enter') handleSubmit() }}>
          <FormCard>
            <FormBanner>
              <FormTitle variant="h5">{props.editItem != null ? 'Edit' : 'Create'} To-Do Item</FormTitle>
              <FormCloseButton onClick={handleFormClose}>
                <Close />
              </FormCloseButton>
            </FormBanner>
            <Divider />
            <FormBody>
              <Grid container spacing={2}>
                <Grid xs={12} md={12} item>
                  <TextField
                    inputProps={{ autoFocus: true }}
                    label="Display Text"
                    color="info"
                    fullWidth
                    value={formDisplayText ?? ''}
                    onChange={(e: any) => setFormDisplayText(e.target.value.replace(/[\[\]]/g, ''))}
                    error={warningDisplayText !== ''}
                    helperText={warningDisplayText}
                  />
                </Grid>
                <Grid xs={4} md={4} item>
                  <FormControl>
                    <FormGroup sx={{ marginTop: '7px' }}>
                      <FormControlLabel
                        control={<Checkbox color="info" />}
                        checked={formAllDays} 
                        label="All Days?"
                        labelPlacement="start"
                        onChange={() => setFormAllDays(!formAllDays)}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid xs={8} md={8} item>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="schedule-label" color="info">Schedule</InputLabel>
                    <Select
                      labelId="schedule-label"
                      label="Schedule"
                      color="info"
                      fullWidth
                      multiple
                      value={formSchedule ?? []}
                      onChange={(e: any) => setFormSchedule(e.target.value)}
                      disabled={formAllDays}
                    >
                      {Object.keys(DaysOfWeek).map(day => <MenuItem key={day} value={day}>{day}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={12} md={12} item>
                  <FormControl>
                    <FormGroup sx={{ marginTop: '7px' }}>
                      <FormControlLabel
                        control={<Checkbox color="info" />}
                        checked={formDifferentToolTips} 
                        label="Different Tooltips for each day?"
                        labelPlacement="start"
                        onChange={() => setFormDifferentToolTips(!formDifferentToolTips)}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                {buildToolTipFields()}
                <Grid xs={12} md={12} item>
                  <FormControl>
                    <FormGroup sx={{ marginTop: '7px' }}>
                      <FormControlLabel
                        control={<Checkbox color="info" />}
                        checked={formIsDropIn} 
                        label="Delete item after today?"
                        labelPlacement="start"
                        onChange={() => {
                          setFormIsDropIn(!formIsDropIn)
                          setFormAllDays(true)
                        }}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </FormBody>
            <Divider />
            <ButtonBar>
              {props.editItem != null ? <FormButton variant="contained" color="error" onClick={handleDelete}>Delete</FormButton> : <></>}
              <FormButton variant="outlined" color="primary" onClick={handleFormClose}>Cancel</FormButton>
              <FormButton variant="contained" color="secondary" onClick={handleSubmit}>Submit</FormButton>
            </ButtonBar>
          </FormCard>
        </FormWrapper>
      </Slide>
    </Modal>
    <CollapseContainer in={props.isEditMode} orientation="horizontal">
      <Fab color="secondary" sx={{ boxShadow: 3 }} onClick={() => {props.setIsEditModalOpen(true)}}>
        <Add />
      </Fab>
    </CollapseContainer>
  </>
}