import styled from 'styled-components'
import { 
  Checkbox, 
  Collapse,
  FormControlLabel, 
  FormGroup, 
  Paper, 
  Tooltip,
} from '@mui/material'
import { Edit } from '@mui/icons-material'
import { ColorSheet, DaysOfWeekAbbreviated } from '../typing/enums'
import { ListContainerProps, ToDoItem } from '../typing/interfaces'
import * as datetimeUtils from '../utilities/DateTimes'
import { isMobile } from 'is-mobile'

// ----- Styles -----
const ToDoListContainer = styled(Paper)`
  display: inline-block;
  padding: 1.5%;
  background-color: ${ColorSheet.ListBackground} !important;
  max-height: ${isMobile() ? '65%' : '50%'};
  min-width: 10%;
  overflow: auto;
  margin-top: ${isMobile() ? '15vh' : '0'};

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`

const ToDoItemLine = styled.div`
  display: flex;
  color: ${ColorSheet.ListText};
  cursor: pointer;
  margin-top: 10px;
  max-width: 60vw;
  overflow: hidden;
  white-space: nowrap;
  :hover {
    text-shadow: 0.75px 0px 0px ${ColorSheet.ListText};
    color: ${ColorSheet.AppBackground}
  }
`

const CollapseContainer = styled(Collapse)`
  padding-right: 5px;
`


export const ListContainer = (props: ListContainerProps) => {
  // ----- Event Handlers -----
  const handleCheckboxClicked = (id: ToDoItem["id"]) => {
    let modifiedtoDoList: Array<ToDoItem> = [...props.toDoList]
    const modifiedIndex: number = props.toDoList.findIndex(item => id === item.id)
    modifiedtoDoList[modifiedIndex].isChecked = !props.toDoList[modifiedIndex].isChecked
    modifiedtoDoList[modifiedIndex].dateChecked = new Date()
    
    props.setToDoList(modifiedtoDoList)
  }

  const handleEditItem = (item: ToDoItem) => {
    props.setEditItem(item)
    props.setIsEditModalOpen(true)
  }


  // ----- Builders -----
  const buildToDoElement = (toDoItem: ToDoItem): JSX.Element => {
    // parse any potential schedules for the label
    let scheduleText = ''
    if (toDoItem.schedule != null) {
      const scheduleShorthand = toDoItem.schedule.map(day => DaysOfWeekAbbreviated[day])
      scheduleText = `[${scheduleShorthand.join('')}]`
    }

    // parse drop ins
    if (toDoItem.isDropIn) {
      scheduleText = '[Today]'
    }

    // add edit icons when in edit mode
    const labelIcons = <CollapseContainer in={props.isEditMode} orientation="horizontal" >
      <Edit />
    </CollapseContainer>
    let handleLabelClick = () => {}
    if (props.isEditMode) {
      handleLabelClick = () => handleEditItem(toDoItem)
    }

    let label = <ToDoItemLine onClick={handleLabelClick}>{labelIcons} {toDoItem.displayText} {scheduleText}</ToDoItemLine>

    // parse any tooltips for the label
    if (toDoItem.toolTip != null && toDoItem.toolTip[datetimeUtils.getDayOfWeek()] !== '') {
      label = <Tooltip 
        placement='right'
        title={toDoItem.toolTip[datetimeUtils.getDayOfWeek()]}>
          {label}
      </Tooltip>
    }

    return <FormGroup key={toDoItem.id}>
        <FormControlLabel
          control={<Checkbox color="secondary" />} 
          label={label}
          checked={toDoItem.isChecked}
          onChange={() => handleCheckboxClicked(toDoItem.id)}
          // TODO - uncomment this at the end
          disabled={props.isComplete || props.isEditMode}
        />
      </FormGroup>
  }


  return <ToDoListContainer elevation={4}>
    { props.children }
    { props.toDoList.map(buildToDoElement) }
    </ToDoListContainer>
}