import { 
  useState,
  useEffect,
} from 'react'
import { 
  AppBar, 
  Toolbar, 
  Box,
  createTheme,
  ThemeProvider, 
  Typography 
} from '@mui/material'
import styled from 'styled-components'
import { ListContainer } from './components/ListContainer'
import { ColorSheet, DbNameMode, LocalStorage } from './typing/enums'
import { DbItem, ToDoItem } from './typing/interfaces'
import { ProgressBar } from './components/ProgressBar'
import { ToDoItemForm } from './components/ToDoItemForm'
import { ControlsDropdown } from './components/ControlsDropdown'
import * as listUtils from './utilities/ListFormatters'
import { LoginForm } from './components/LoginForm'
import { DbNameForm } from './components/DbNameForm'
import { isMobile } from 'is-mobile'

// ----- Styles -----
const AppBody = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${ColorSheet.AppBackground};
  color: ${ColorSheet.CheckboxEmpty};
`

const AppBanner = isMobile() ?
  styled(Toolbar)`
    display: flex;
    flex-wrap: wrap;
  ` :
  styled(Toolbar)``

const ColorTheme = createTheme({
  palette: {
    primary: {
      main: ColorSheet.ListBackground
    },
    secondary: {
      main: ColorSheet.CheckboxFilled
    },
    info: {
      main: ColorSheet.AppBackground
    }
  }
})

// ----- Local Storage -----
const saveToLocalStorage = (listToSave: Array<ToDoItem>) => {
  const listJson = JSON.stringify(listToSave)
  localStorage.setItem(LocalStorage.ToDoList, listJson)
}

const loadLegacyFromStorage = (): DbItem|null => {
  const legacyLSList = localStorage.getItem(LocalStorage.LegacyList)
  try {
    const legacyListParsed = legacyLSList != null ? JSON.parse(legacyLSList) : null
    if (legacyListParsed == null) return null
    else return {
      toDoList: legacyListParsed,
      toDoChecks: {},   // unused when migrating
      toolTips: {},     // unused when migrating
      userName: '',     // unused when migrating
    }
  } catch (err) {
    return null
  }
}

const loadFromLocalStorage = (): Array<ToDoItem>|null => {
  const localStorageItemRaw = localStorage.getItem(LocalStorage.ToDoList)
  try {
    const localStorageItem = localStorageItemRaw != null ? JSON.parse(localStorageItemRaw) : null
    if (localStorageItem == null) {
      const legacyLocalStorage = loadLegacyFromStorage()
      const legacyFormatted = legacyLocalStorage == null ? null : listUtils.reformatLegacyList(legacyLocalStorage)
      return legacyFormatted
    }
    return localStorageItem
  } catch (err) {
    console.error('Error reading local storage item: ', err)
    return null
  }
}


const App = () => {
  // ----- Init State -----
  const getInitUsername = () => {
    let username = localStorage.getItem(LocalStorage.Username)
    if (username != null) return username
      
    username = localStorage.getItem(LocalStorage.LegacyUserName)
    if (username != null) {
      localStorage.setItem(LocalStorage.Username, username.toUpperCase())
      return username.toUpperCase()
    }

    return null
  }
  const getInitIsComplete = (someToDoList: Array<ToDoItem>): boolean => someToDoList.every(item => item.isChecked)
  const getInitToDoList = (toDoList: Array<ToDoItem>|null = null) => {
    let listToBeFormatted: Array<ToDoItem> = loadFromLocalStorage() ?? []
    if (toDoList != null) listToBeFormatted = toDoList

    listToBeFormatted = listUtils.formatStringsToDates(listToBeFormatted)
    listToBeFormatted = listUtils.removeExpiredChecks(listToBeFormatted)
    listToBeFormatted = listUtils.removeExpiredItems(listToBeFormatted)
    listToBeFormatted = listUtils.setScheduledChecks(listToBeFormatted)

    return listToBeFormatted
  }

  // ----- Define State -----
  const [username, setUsername]: [string|null, Function] = useState(getInitUsername())
  const [toDoList, setToDoList]: [Array<ToDoItem>, Function] = useState(getInitToDoList())
  const [isComplete, setIsComplete]: [boolean, Function] = useState(getInitIsComplete(toDoList))
  const [isEditMode, setIsEditMode]: [boolean, Function] = useState(false)
  const [editItem, setEditItem]: [ToDoItem|null, Function] = useState(null)
  const [isEditModalOpen, setIsEditModalOpen]: [boolean, Function] = useState(false)
  const [isDbNameModalOpen, setDbNameModalOpen]: [boolean, Function] = useState(false)
  const [dbNameMode, setDbNameMode]: [DbNameMode, Function] = useState(DbNameMode.Load)

  // ----- Manually Maintain State -----
  useEffect(() => {setToDoList([...toDoList].sort(listUtils.sortToDoList))}, [editItem])  // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {saveToLocalStorage(toDoList)}, [toDoList])

  return (
    <AppBody>
      <ThemeProvider theme={ColorTheme}>
        <AppBar color="primary">
          <AppBanner>
            <ControlsDropdown 
              isEditMode = { isEditMode }
              setIsEditMode = { setIsEditMode }
              setDbNameModalOpen = { setDbNameModalOpen }
              setDbNameMode = { setDbNameMode }
              username = { username }
            />
            <Typography variant="h5" sx={{ flexGrow: 1 }}>Daily Checklist</Typography>
            <LoginForm 
              username = { username }
              setUsername = { setUsername }
            />
          </AppBanner>
        </AppBar>
        <ListContainer 
          toDoList = { toDoList }
          setToDoList = { setToDoList }
          isComplete = { isComplete }
          isEditMode = { isEditMode }
          setEditItem = { setEditItem }
          setIsEditModalOpen = { setIsEditModalOpen }
        >
          <ToDoItemForm
            isEditMode = { isEditMode }
            isEditModalOpen = { isEditModalOpen }
            setIsEditModalOpen = { setIsEditModalOpen }
            editItem = { editItem }
            toDoList = { toDoList }
            setEditItem = { setEditItem }
            setToDoList = { setToDoList }
          />
        </ListContainer>
        <ProgressBar 
          toDoList = { toDoList }
          isComplete = { isComplete }
          setIsComplete = { setIsComplete }
        />
        <DbNameForm 
          isDbNameModalOpen = { isDbNameModalOpen }
          setDbNameModalOpen = { setDbNameModalOpen }
          dbNameMode = { dbNameMode }
          username = { username }
          toDoList = { toDoList }
          getInitToDoList = { getInitToDoList }
          setToDoList = { setToDoList }
        />
      </ThemeProvider>
    </AppBody>
  );
}

export default App;
