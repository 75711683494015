export enum ColorSheet {
  AppBackground = '#33658A',
  CheckboxFilled = '#009201',
  CheckboxEmpty = 'white',
  ListBackground = '#96CBE8',
  ListText = 'black',
  Title = AppBackground,
}

export enum DaysOfWeek {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export enum DaysOfWeekAbbreviated {
  Monday = 'M',
  Tuesday = 'T',
  Wednesday = 'W',
  Thursday = 'R',
  Friday = 'F',
  Saturday = 'S',
  Sunday = 'U',
}

export enum LocalStorage {
  ToDoList = 'stod-checklist-application-to-do-list',
  Username = 'stod-checklist-application-username',
  LegacyList = 'stodCheckListApp-toDoList',
  LegacyChecksPrefix = 'stodCheckListApp-toDoChecks-',
  LegacyUserName = 'stodCheckListApp-userName',
}

export enum DbNameMode {
  Load = 'Load',
  Save = 'Save',
}