import { DaysOfWeek } from "../typing/enums"
import { ToDoItem } from "../typing/interfaces"

const today = new Date()
const dayNames = {
  0: DaysOfWeek.Sunday,
  1: DaysOfWeek.Monday,
  2: DaysOfWeek.Tuesday,
  3: DaysOfWeek.Wednesday,
  4: DaysOfWeek.Thursday,
  5: DaysOfWeek.Friday,
  6: DaysOfWeek.Saturday,
} as const

// ignoring typescript here because sometimes it doesn't actually know how javascript works
// TODO - see if typescript can be convinced to work
export const getDayOfWeek = (date: Date = today): DaysOfWeek => ((dayNames as any)[date.getDay()])

export const getDateIsExpired = (date: Date): boolean => {
  let todayDay = new Date()
  todayDay.setHours(0,0,0,0)
  date.setHours(0,0,0,0)
  return date < todayDay
}

export const getScheduledForToday = (schedule: ToDoItem["schedule"]): boolean => {
  const dayOfWeek = getDayOfWeek()
  if (schedule) return schedule.includes(dayOfWeek)
  else return true  // if there is no schedule, it is scheduled for every day
}