import {
  Button, Collapse, TextField,
} from '@mui/material'
import styled from 'styled-components'
import { LoginFormProps } from "../typing/interfaces";
import { ColorSheet, LocalStorage } from '../typing/enums'
import { useState } from 'react';
import { isMobile } from 'is-mobile'

const FormContainer = isMobile() ? 
  styled.div`
    flex-basis: 100%;
    height: 100%;
  ` : 
  styled.div`
    display: flex;
  `

const LoginButton = styled(Button)`
  color: ${ColorSheet.AppBackground} !important;
`

export const LoginForm = (props: LoginFormProps) => {
  // ----- Define State -----
  const [loginFieldOpen, setLoginFieldOpen]: [boolean, Function] = useState(false)
  const [formUsername, setFormUsername]: [string, Function] = useState('')

  // ----- Event Handlers -----
  const handleFormClose = () => {
    setLoginFieldOpen(false)
    setFormUsername('')
  }

  const handleUsernameSubmit = () => {
    if (formUsername === '') return
    const newUsername = formUsername.toUpperCase().replace('-', '')

    props.setUsername(newUsername)
    localStorage.setItem(LocalStorage.Username, newUsername)

    handleFormClose()
  }

  const handleLogout = () => {
    props.setUsername(null)

    const lsUsername = localStorage.getItem(LocalStorage.Username)
    const lsLegacyUsername = localStorage.getItem(LocalStorage.LegacyUserName)
    if (lsUsername != null) localStorage.removeItem(LocalStorage.Username)
    if (lsLegacyUsername != null) localStorage.removeItem(LocalStorage.LegacyUserName)

    handleFormClose()
  }

  const handleLoginButtonClicked = () => {
    if (props.username == null && !loginFieldOpen) setLoginFieldOpen(true)
    else if (props.username == null && loginFieldOpen) handleUsernameSubmit()
    else handleLogout()
  }

  return <FormContainer>
    <Collapse in={loginFieldOpen} orientation={isMobile() ? "vertical" : "horizontal"} unmountOnExit={isMobile()}>
      <TextField 
        value={formUsername}
        label='Username'
        color='info'
        onChange={(e: any) => setFormUsername(e.target.value.toUpperCase().replace('-', ''))}
        onKeyDown={(e: any) => { if (e.key === 'Enter') handleUsernameSubmit() }}
        sx={{ width: '250px' }}
      />
    </Collapse>
    <LoginButton sx={isMobile() ? { float: 'right' } : {}} onClick={handleLoginButtonClicked}>{props.username == null ? 'Login' : `${props.username} (Logout)`}</LoginButton>
  </FormContainer>
}