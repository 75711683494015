import { useEffect, useState } from 'react'
import { ProgressBarProps } from "../typing/interfaces"
import { 
  LinearProgress, 
  Zoom,
} from '@mui/material'
import styled from "styled-components"
import { ColorSheet } from "../typing/enums"
import { isMobile } from 'is-mobile'

// ----- Styles -----
const BarContainer = styled.div`
  flex-basis: 100%;
  margin-top: ${isMobile() ? '5vh' : '-25vh'};
`

const Bar = styled(LinearProgress)`
  width: ${isMobile() ? '90%' : '50%'};
  margin: auto;
  height: 20px !important;
  border-radius: 20px;
`

const CheckMark = styled.img`
  position: relative;
  left: calc(50% - 50px);
  bottom: 60px;
  height: 100px;
  width: 100px;
  z-index: 5;
  background-color: ${ColorSheet.AppBackground};
`

export const ProgressBar = (props: ProgressBarProps) => {
  // ----- Init/Calc State -----
  const getProgressPercentage = (): number => {
    let completedToDoCount = 0
    let totalToDoCount = 0
    props.toDoList.forEach(toDoItem => {
      if (toDoItem.isChecked) completedToDoCount++
      totalToDoCount++
    })

    return ( completedToDoCount / totalToDoCount ) * 100
  }

  // ----- Define State -----
  const [progressPercentage, setProgressPercentage]: [number, Function] = useState(getProgressPercentage())

  // Weird react dependency warning disabled below. No noticeable impacts, but should do more research later to understand this warning.    
  useEffect(() => setProgressPercentage(getProgressPercentage()), [props.toDoList])  // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => props.setIsComplete(progressPercentage === 100), [progressPercentage])  // eslint-disable-line react-hooks/exhaustive-deps

  return <BarContainer>
    <Bar variant="determinate" value={progressPercentage} color="secondary" />
    <Zoom in={props.isComplete}>
      <CheckMark src="/checkmark.png" alt="checkmark"/>
    </Zoom>
  </BarContainer>
}