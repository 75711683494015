import { 
  useState,
  MouseEvent,
} from 'react'
import { 
  Divider,
  IconButton, 
  Menu,
  MenuItem, 
  Switch,
} from "@mui/material"
import { Menu as MenuIcon } from '@mui/icons-material'
import { ControlsDropdownProps } from "../typing/interfaces"
import { DbNameMode } from '../typing/enums'

export const ControlsDropdown = (props: ControlsDropdownProps) => {
  // ----- Define State -----
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null)
  const [isControlsOpen, setIsControlsOpen]: [boolean, Function] = useState(false)

  // ----- Event Handlers -----
  const handleMenuButtonClicked = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget)
    setIsControlsOpen(!isControlsOpen)
  }

  const handleSaveButtonClicked = () => {
    if (props.username == null) {
      alert('You must be logged in to save to the database.')
      return
    }
    props.setDbNameMode(DbNameMode.Save)
    props.setDbNameModalOpen(true)
  }

  const handleLoadButtonClicked = () => {
    if (props.username == null) {
      alert('You must be logged in to load from the database.')
      return
    }
    props.setDbNameMode(DbNameMode.Load)
    props.setDbNameModalOpen(true)
  }

  return <IconButton
      size="large"
      edge="start"
      color="inherit"
      sx={{ mr: 2 }}
      onClick={handleMenuButtonClicked}
    >
    <MenuIcon />
    <Menu 
      anchorEl={anchorElement}
      open={isControlsOpen}
      onClose={() => setIsControlsOpen(false)}
    >
      <MenuItem onClick={() => props.setIsEditMode(!props.isEditMode)}>
        Toggle Edit Mode 
        <Switch checked={props.isEditMode} color='secondary' />
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleSaveButtonClicked}>Save Checklist</MenuItem>
      <MenuItem onClick={handleLoadButtonClicked}>Load Checklist</MenuItem>
    </Menu>
  </IconButton>
}