import { 
  CircularProgress,
  Modal, 
  Paper, 
  Slide, 
  TextField,
} from "@mui/material";
import { Close } from "@mui/icons-material"
import styled from "styled-components";
import { DbNameFormProps, ToDoItem } from "../typing/interfaces";
import * as crud from '../utilities/Crud'
import {
  FormCard,
  FormBanner,
  FormTitle,
  FormCloseButton,
  FormBody,
  ButtonBar,
  FormButton,
} from './ToDoItemForm'
import { ColorSheet, DbNameMode, LocalStorage } from "../typing/enums";
import { useEffect, useState } from "react";

const FormModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const FormNote = styled(Paper)`
  background-color: ${ ColorSheet.ListBackground } !important;
  border: 1px solid ${ ColorSheet.AppBackground };
  color: ${ ColorSheet.AppBackground } !important;
  margin: 10px;
  padding: 10px;
  margin-top: 20px;
`

const DbSpinner = styled(CircularProgress)`
  position: relative;
  width: 40px !important;
  left: calc(50% - 20px);
  margin-top: 10px;
`

export const DbNameForm = (props: DbNameFormProps) => { 
  // ----- Define State -----
  const [formName, setFormName]: [string, Function] = useState('')
  const [isWaitingDb, setIsWaitingDb]: [boolean, Function] = useState(false)
  const [warningName, setWarningName]: [string, Function] = useState('')

  // ----- Manually Recalculate Fields -----
  useEffect(() => setWarningName(''), [formName])  // eslint-disable-line react-hooks/exhaustive-deps

  // ----- Event Handlers -----
  const handleClose = () => {
    props.setDbNameModalOpen(false)
    setFormName('')
    setWarningName('')
    setIsWaitingDb(false)
  }

  const handleSubmit = async () => {
    if (formName === '') setWarningName('Cannot be blank')
    setIsWaitingDb(true)

    if (props.username == null) return
    const existingLists = await crud.readAllLists(props.username)
    if (props.dbNameMode === DbNameMode.Save) {
      let userConfirm = true
      if (existingLists.includes(formName)) userConfirm = window.confirm(`There is already a list named "${formName}". Are you sure you want to overwrite it?`)
      if (!userConfirm) {
        setIsWaitingDb(false)
        return
      }
      
      // for now, no difference between create and update
      crud.createList(props.username, formName, props.toDoList)
    } else {
      if (!existingLists.includes(formName)) {
        setIsWaitingDb(false)
        alert(`Could not find list "${formName}" for user "${props.username}" in the database.`)
        return
      }
      const response: Array<ToDoItem> | null = await crud.readList(props.username, formName)
      if (response != null && response.length > 0) {
        const formattedToDoList = props.getInitToDoList(response)
        props.setToDoList(formattedToDoList)
        localStorage.setItem(LocalStorage.ToDoList, JSON.stringify(formattedToDoList))
      }
    }

    handleClose()
  }
  
  return <FormModal
    open={props.isDbNameModalOpen}
    onClose={() => props.setDbNameModalOpen(false)}
  >
    <Slide in={props.isDbNameModalOpen} direction="down">
      <FormCard>
        <FormBanner>
          <FormTitle variant="h5">{props.dbNameMode === DbNameMode.Save ? 'Save To' : 'Load From'} Database</FormTitle>
          <FormCloseButton onClick={handleClose}>
            <Close />
          </FormCloseButton>
        </FormBanner>
        <FormBody>
          <TextField
            inputProps={{ autoFocus: true }}
            label="Checklist Name"
            color="info"
            fullWidth
            value={formName}
            onChange={(e: any) => setFormName(e.target.value.toLowerCase().replace('-', ''))}
            error={warningName !== ''}
            helperText={warningName}
            onKeyDown={(e: any) => { if (e.key === 'Enter') handleSubmit() }}
          />
          {isWaitingDb ? 
          <DbSpinner color="secondary" /> :
          <FormNote>
            NOTE: LOADING a checklist from the database will overwrite your local checklist, 
            and SAVING a checklist will overwrite your checklist in the database of the same name.
            <br /><br />
            All checklist names in the database are unique to your login name. For example, a checklist
            named "Primary" for the user "SomeUser" will get stored as "someuser-primary" in the database.
          </FormNote>
          }
        </FormBody>
        <ButtonBar>
          <FormButton variant="outlined" color="primary" onClick={handleClose}>Cancel</FormButton>
          <FormButton variant="contained" color="secondary" onClick={handleSubmit} disabled={isWaitingDb}>Submit</FormButton>
        </ButtonBar>
      </FormCard>
    </Slide>
  </FormModal>
}