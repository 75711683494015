import { ToDoItem } from "../typing/interfaces";
import * as listformatters from "../utilities/ListFormatters"

export const createList = async (username: string, listName: string, toDoList: Array<ToDoItem>) => {
  const dbKey = [username.toLowerCase(), listName].join('-')
  try {
    const body = {
      toDoList
    }

    const response = await fetch(`https://pl1zyr0rp9.execute-api.us-east-1.amazonaws.com/prod/item/${dbKey}`, {
      method: 'PUT',
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
    })

    const parsedResponse = await response.json()
    console.log(parsedResponse)
  } catch (err) {
    alert(err)
  }
}

export const readList = async (username: string, listName: string) => {
  const dbKey = [username.toLowerCase(), listName].join('-')
  try {
    const data = await fetch(`https://pl1zyr0rp9.execute-api.us-east-1.amazonaws.com/prod/item/${dbKey}`, {
      method: 'GET',
      headers: {
        "content-type": "application/json",
      },
    })

    const parsedData = await data.json()
    let toDoItem = parsedData.Item
    if (toDoItem.hasOwnProperty('toDoChecks')) return listformatters.reformatLegacyList(toDoItem)
    else if (toDoItem.hasOwnProperty('toDoList')) return toDoItem.toDoList
    else return null
  } catch (err) {
    console.error(`Error reading database item ${dbKey}: `, err)
    return null
  }
}

export const readAllLists = async (username: string) => {
  username = username.toLowerCase()

  try {
    const data = await fetch(`https://pl1zyr0rp9.execute-api.us-east-1.amazonaws.com/prod/items`, {
      method: 'GET',
      headers: {
        "content-type": "application/json",
      },
    })

    const parsedData = await data.json()
    const filteredData = parsedData.Items.filter((obj: any) => {
      const fullObjName = obj.userName
      const [loginName, ] = fullObjName.split('-')
      return loginName === username
    })
    
    return filteredData.map((obj: any) => {
      const fullObjName = obj.userName
      const [, ...listName] = fullObjName.split('-')  // join used to parse legacy list names which could include "-"
      return listName.join('')
    })
  } catch (err) {
    console.error('Error reading database all-items: ', err)
    return null
  }
}