import { DaysOfWeekAbbreviated } from '../typing/enums'
import { DbItem, ToDoItem } from '../typing/interfaces'
import * as datetimeUtils from './DateTimes'

export const sortToDoList = (firstToDo: ToDoItem, secondToDo: ToDoItem) => {
  if (firstToDo.id > secondToDo.id) return 1
  if (firstToDo.id < secondToDo.id) return -1
  return 0
}

export const formatStringsToDates = (toDoList: Array<ToDoItem>): Array<ToDoItem> => {
  return toDoList.map(item => {
    if (item.dateChecked != null) item.dateChecked = new Date(item.dateChecked)
    if (item.dateDroppedIn != null) item.dateDroppedIn = new Date(item.dateDroppedIn)
    return item
  })
}

export const removeExpiredChecks = (toDoList: Array<ToDoItem>): Array<ToDoItem> => {
  const formattedList = toDoList.map(item => {
    if (item.dateChecked != null && datetimeUtils.getDateIsExpired(item.dateChecked)) {
      delete item.dateChecked
      item.isChecked = false
    }

    return item
  })

  return formattedList
}

export const removeExpiredItems = (toDoList: Array<ToDoItem>): Array<ToDoItem> => {
  let idsToRemove: Array<number> = []
  toDoList.forEach(item => {
    if (item.dateDroppedIn != null && datetimeUtils.getDateIsExpired(item.dateDroppedIn)) idsToRemove.push(item.id)
  })

  const formattedList = toDoList.filter(item => !idsToRemove.includes(item.id))
  return formattedList
}

export const setScheduledChecks = (toDoList: Array<ToDoItem>): Array<ToDoItem> => {
  const formattedList = toDoList.map(item => {
    if (!datetimeUtils.getScheduledForToday(item.schedule)) item.isChecked = true 
    else if (item.isChecked && item.dateChecked == null) item.isChecked = false
    return item
  })

  return formattedList
}

export const reformatLegacyList = (dbItem: DbItem) => {
  const reformattedToDo: Array<ToDoItem> = dbItem.toDoList.map((item, index) => {
    const [text, scheduleString, ] = item.split(/[\[\]]/g)
    let itemSchedule = []
    const daysOfWeekAbbreviated = Object.entries(DaysOfWeekAbbreviated)
    if (scheduleString != null) {
      for (let char of scheduleString.split('')) {
        itemSchedule.push((daysOfWeekAbbreviated.find(day => day[1] === char) as any)[0])
      }
    }
    
    return {
      id: index,
      displayText: text,
      isChecked: false,
      isDropIn: false,
      schedule: itemSchedule.length > 0 ? itemSchedule : undefined,
      // toolTip: [],  - I'm the only one who has ever used tooltips in V1 so forget it. Can recreate maunally.
    }
  })
  return reformattedToDo
}